<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-table-account</v-icon> Liquidaciones y anticipos</h1><br>
        <v-layout>
          <v-flex xs4>
            <v-select
              label="Producto"
              v-model="producto"
              outlined
              :items="productos"
              item-text="n"
              item-value="v"
              @change="loadCategory(); getCampanas(); filter();"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select
              label="Calidad / categoría"
              outlined
              v-model="calidad"
              :items="calidades"
              item-text="nombre"
              item-value="nombre"
              @change="filter"
            ></v-select>
          </v-flex>
          <v-flex xs4>
            <v-select
              label="Tipo de liquidacion"
              v-model="tipoliquidacion"
              outlined
              :items="tiposliquidacion"
              item-text="name"
              item-value="value"
              @change="filter"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs2>
            <v-menu
              v-model="menuDateDesde"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedDesde"
                  label="Desde"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaLiquidacionDesde'); read();"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputDesde"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-menu
              v-model="menuDateHasta"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormattedHasta"
                  label="Hasta"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fechaLiquidacionHasta'); read();"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInputHasta"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-select
              label="Campaña"
              outlined
              v-model="campana"
              :items="campanas"
              @change="filter"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-btn class="btnCustom" @click="clear"><v-icon class="btnIcon">mdi-autorenew</v-icon> Limpiar filtros</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="showResumen"
        >
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Producto',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      producto: null,
      productos: [{n: 'Aceituna', v: 'Aceituna'},{n: 'Frutos secos', v: 'Cascara'},{n: 'Frutales', v: 'Frutales'}],
      calidades: [],
      calidad: null,
      campana: null,
      campanas: [],
      tipoliquidacion: null,
      tiposliquidacion: [{name:'Liquidación por kilos', value:'Liquidación'}, {name:'Anticipo de campaña', value:'Anticipo'}],
      menuDateDesde: '',
      menuDateHasta: '',
      dateFormattedDesde: '',
      dateInputDesde: '',
      dateFormattedHasta: '',
      dateInputHasta: '',
      headersResumen: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha',
        },
        { text: 'Campaña', value: 'campana' },
        { text: 'Producto', value: 'valorproducto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Tipo', value: 'valortipo' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Precio €/kg', value: 'precio' },
        { text: 'Escandallo', value: 'escandallo' },
        { text: 'IVA', value: 'iva' },
        { text: 'IRPF', value: 'irpf' },
        { text: 'Total', value: 'total' },
        { text: '', value: 'actions', sortable: false },
      ],
      resumen: [],
      showResumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInputDesde = basics.getDate("month", -1);
    this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy");
    this.dateInputHasta = basics.getDate();
    this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy");
    this.read();
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechaLiquidacionDesde":
          if(this.dateInputDesde){
            this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy");
          }
          break;
        case "fechaLiquidacionHasta":
          if(this.dateInputHasta){
            this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy");
          }
          break;
      }
    },
    clear(){
      this.producto = null;
      this.dateInputDesde = basics.getDate("month", -1),
      this.dateFormattedDesde = basics.formatDate(this.dateInputDesde,"dd/mm/yyyy"),
      this.dateInputHasta = basics.getDate(),
      this.dateFormattedHasta = basics.formatDate(this.dateInputHasta,"dd/mm/yyyy"),
      this.calidad = null;
      this.tipoliquidacion = null;
      this.campana = null;
      this.campanas = [];
      this.read();
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `liquidacion/?estado=aceptado&fechaInicio=`+this.dateInputDesde+`&fechaFin=`+this.dateInputHasta);
      if (response.status === 200) {
        const json = await response.json();
        for(let i = 0; i<json.body.length; i++){
          let total = json.body[i].kilos * json.body[i].precioKilo;
          if(json.body[i].tipoescandallo=="euro"){
            if(json.body[i].escandallo!=""){
              total = parseFloat(total) - parseFloat(json.body[i].escandallo);
            }
          }else{
            if(json.body[i].escandallo!=""){
              total = total - ((total * parseFloat(json.body[i].escandallo))/100);
            }
          }
          if(json.body[i].iva!=""){
            total = total + ((total * parseFloat(json.body[i].iva))/100);
          }
          if(json.body[i].irpf!=""){
            total = total - ((total * parseFloat(json.body[i].irpf))/100);
          }
          total = Math.round(total * 100) / 100;
          let tipo = (json.body[i].tipo == "K") ? "Liquidación" : "Anticipo"
          let producto = (json.body[i].producto == "Cascara") ? "Frutos secos" : json.body[i].producto
          this.resumen.push({
            id: json.body[i].id, 
            fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
            valorproducto: producto,
            producto: json.body[i].producto,
            calidad: json.body[i].calidad,
            tipo: json.body[i].tipo,
            valortipo: tipo,
            kilos: json.body[i].kilos,
            precio: json.body[i].precioKilo,
            campana: json.body[i].campana,
            valorescandallo: json.body[i].escandallo,
            escandallo: (json.body[i].tipoescandallo == "euro") ? json.body[i].escandallo+"€" : json.body[i].escandallo+"%",
            tipoescandallo: json.body[i].tipoescandallo,
            iva: json.body[i].iva,
            irpf: json.body[i].irpf,
            total: total,
          });
        }
        this.filter();
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de liquidaciones y anticipos anteriores", "Cerrar", "red");
      }
    },
    filter(){
      this.showResumen = this.resumen;
      if(this.producto){
        this.showResumen = this.resumen.filter((f) => f.producto == this.producto);
      }
      if(this.calidad){
        this.showResumen = this.resumen.filter((f) => f.calidad == this.calidad);
      }
      if(this.tipoliquidacion){
        this.showResumen = this.resumen.filter((f) => f.valortipo == this.tipoliquidacion);
      }
      if(this.campana){
        this.showResumen = this.resumen.filter((f) => f.campana == this.campana);
      }
    },
    async getCampanas(){
      this.campanas = [];
      const response = await genericReload(this.$store.state.auth, `entrada/?tipo=${this.producto}`);
      let arrayCampanas = []
      if (response.status === 200) {
        const json = await response.json();
        if(json.body.length > 0){
          for(let i = 0; i<json.body.length; i++){
            arrayCampanas.push(json.body[i].fecha);
          }
          arrayCampanas.sort(function(a,b){ return new Date(a) - new Date(b); });
          const anoInicio = parseInt(arrayCampanas[0].split("-")[0]);
          const anoFin = parseInt((new Date).getFullYear());
          const numCampanas= anoFin - anoInicio + 1;
          let i = 0;
          while(i<=numCampanas){
            this.campanas.push((anoInicio-1+i)+"/"+(anoInicio+i));
            i++;
          }
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las campañas en las que dispones de entradas de producto", "Cerrar", "red");
      }
    },
    async loadCategory(){
      this.calidades = [];
      const response = await genericReload(this.$store.state.auth, `categoria`);
      if (response.status === 200) {
        const json = await response.json();
        this.calidades = JSON.parse(JSON.stringify(json.body.filter((product) => product.producto === this.producto)));
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar las calidades / categorías para el producto seleccionado", "Cerrar", "red");
      }
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>